<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Dados do paciente</h2>
        <hr />
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            disabled
            :value="this.paciente.nome"
            label="Nome completo"
            required
          ></v-text-field>

          <v-text-field
            disabled
            :value="this.paciente.nome_pai"
            label="Nome do pai "
            required
          ></v-text-field>

          <v-text-field
            disabled
            :value="this.paciente.nome_mae"
            label="Nome da mãe"
            required
          ></v-text-field>

          <div class="row">
            <div class="col-md-3">
              <v-text-field
                disabled
                :value="this.paciente.ubs"
                label="UBS"
                required
              ></v-text-field>
            </div>
            <div class="col-md-3">
              <v-text-field
                disabled
                :value="this.paciente.sus"
                label="Cartão SUS"
                required
              ></v-text-field>
            </div>
            <div class="col-md-3">
              <v-text-field
                disabled
                :value="this.paciente.pessoa.rg"
                label="RG"
                required
              ></v-text-field>
            </div>
            <div class="col-md-3">
              <v-text-field
                disabled
                :value="this.paciente.pessoa.cpf"
                label="CPF"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <v-text-field
                disabled
                :value="this.paciente.estado_civil.descricao"
                label="Estado Civil"
                required
              ></v-text-field>
            </div>
            <div class="col-md-3">
              <v-text-field
                disabled
                :value="this.paciente.telefone"
                label="Telefone"
                required
              ></v-text-field>
            </div>
            <div class="col-md-3">
              <v-text-field
                disabled
                :value="this.paciente.pessoa.data_nascimento"
                label="Data de nascimento"
                required
              ></v-text-field>
            </div>
            <div class="col-md-3">
              <v-text-field
                disabled
                :value="this.paciente.endereco.municipio"
                label="Município"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <v-text-field
                disabled
                :value="this.paciente.endereco.rua"
                label="Rua"
                required
              ></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field
                disabled
                :value="this.paciente.endereco.numero"
                label="numero"
                required
              ></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field
                disabled
                :value="this.paciente.endereco.bairro"
                label="bairro"
                required
              ></v-text-field>
            </div>
          </div>

          <router-link to="/PainelPacientes" v-slot="{ href, navigate }">
            <a :href="href" class="menu-link" @click="navigate">
              <v-btn class=" textStyle" tile color="#1dd1a1">
                <span style="color: white">Voltar</span>
              </v-btn>
            </a>
          </router-link>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
export default {
  name: "VisualizarPaciente",
  async created() {
    try {
      const response = await ambulatorio.indexPaciente(this.$route.params.id);
      this.paciente = response.data;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      items: ["Baixa", "Média", "Alta"],
      paciente: {
        id: 0,
        nome_social: "",
        nome_mae: "",
        nome_pai: "",
        telefone: "",
        ubs: "",
        sus: "",
        prontuario: "",
        prioridade: "",
        created_at: "",
        updated_at: "",
        estado_civil_id: 0,
        endereco_id: 0,
        pessoa_id: 0,
        estado_civil: {
          descricao: ""
        },
        endereco: {
          rua: "",
          numero: 0,
          bairro: "",
          municipio: ""
        },
        pessoa: {
          nome: "",
          data_nascimento: "",
          rg: "",
          cpf: "",
          sexo_id: 0,
          sexo: {
            descricao: ""
          }
        }
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Visualizar paciente" }]);
  }
};
</script>
